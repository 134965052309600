/* eslint-disable global-require */
/* eslint-disable camelcase */
import Vue from 'vue';
import Vuex from 'vuex';
import SICUDsicOcrCon from '@/store/SICUDsic_ocr_con';
import SIRqsmartAPI from '@/store/SIRqsmartAPI';
import SIRqsmartAPINoAuth from '@/store/SIRqsmartAPINoAuth';
import SIRqsmartAPINoAuthPersonal from '@/store/SIRqsmartAPINoAuthPersonal';
import SIRsic_ocr_dcli from '@/store/SIRsic_ocr_dcli';
import SIRsic_usr_nit from '@/store/SIRsic_usr_nit';
import token from '@/store/inicioJustToken';
import messaging from './messaging';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    messaging,
    SICUDsicOcrCon,
    SIRqsmartAPI,
    SIRqsmartAPINoAuth,
    SIRqsmartAPINoAuthPersonal,
    SIRsic_ocr_dcli,
    SIRsic_usr_nit,
    token,
  },

  state: {
    current_page: undefined,

    dialog_settings: false,
    dialog_upload_document: false,
    dialog_upload_audio: false,

    drawer_left: false,
    drawer_right: false,
    edit_disabled: true,
    file_type_selected: undefined,

    icons: {
      alert: 'mdi-bell',
      camera: 'mdi-camera-outline',
      close: 'mdi-close',
      conf: 'mdi-cog',
      edit: 'mdi-pencil',
      editoff: 'mdi-pencil-off',
      file: 'mdi-paperclip',
      image: 'mdi-image',
      left: 'mdi-chevron-left',
      json: 'mdi-code-braces',
      microphone: 'mdi-microphone',
      microphoneoff: 'mdi-microphone-off',
      right: 'mdi-chevron-right',
      upload: 'mdi-upload',
      table: 'mdi-table',
      tableOff: 'mdi-table-off',
    },

    icons_visible: false,
    image: undefined,
    json: undefined,
    json_disabled: true,
    language_selected: undefined,
    loading: false,

    menu: [
      // {
      //   disabled: true,
      //   icon: 'mdi-microphone',
      //   id: 1,
      //   title: 'menu.audio.name',
      // },
      // {
      //   disabled: true,
      //   icon: 'mdi-file-upload',
      //   id: 2,
      //   title: 'menu.document.name',
      // },
      {
        disabled: false,
        icon: 'mdi-cog',
        id: 3,
        title: 'menu.config.name',
      },
    ],

    qsmart_avatar: {
      email: '',
      image: require('@/assets/avatar.jpg'),
      name: 'Qsmart',
    },

    qsmart_brand: {
      logo: require('@/assets/logo.png'),
      name: 'Qsmart',
      ratio: '2.66',
    },

    qsmart_type_current: undefined,

    viewer: 'image',
  },

  mutations: {
    SET_MENU_DISABLED(state, value) {
      state.menu[0].disabled = value;
      // state.menu[1].disabled = value;
    },

    SET_CURRENT_PAGE(state, value) {
      state.current_page = value;
    },

    SET_DIALOG_UPLOAD_AUDIO(state, flag) {
      state.dialog_upload_audio = flag;
    },

    SET_DIALOG_UPLOAD_DOCUMENT(state, flag) {
      state.dialog_upload_document = flag;
    },

    SET_DIALOG_SETTINGS(state, flag) {
      state.dialog_settings = flag;
    },

    SET_DRAWER_LEFT(state, flag) {
      state.drawer_left = flag;
    },

    SET_DRAWER_RIGHT(state, flag) {
      state.drawer_right = flag;
    },

    SET_EDIT_DISABLED(state, flag) {
      state.edit_disabled = flag;
    },

    SET_FILE_TYPE_SELECTED(state, type) {
      state.file_type_selected = type;
    },

    SET_ICONS_VISIBLE(state, flag) {
      state.icons_visible = flag;
    },

    SET_IMAGE(state, data) {
      state.image = data;
    },

    SET_JSON(state, json) {
      state.json = json;
    },

    SET_JSON_DISABLED(state, flag) {
      state.json_disabled = flag;
    },

    SET_LANGUAGE_SELECTED(state, data) {
      state.language_selected = data;
    },

    SET_LOADING(state, flag) {
      state.loading = flag;
    },

    SET_QSMART_TYPE_CURRENT(state, value) {
      state.qsmart_type_current = value;
    },

    SET_VIEWER(state, data) {
      state.viewer = data;
    },
  },

  actions: {
    setqsmartTypeCurrent({ commit }, value) {
      commit('SET_QSMART_TYPE_CURRENT', value);
    },

    toggleCurrentPage({ commit }, value) {
      commit('SET_CURRENT_PAGE', value);
    },

    toggleDialogSettings({ commit }, flag) {
      commit('SET_DIALOG_SETTINGS', flag);
    },

    toggleDialogUploadAudio({ commit }, flag) {
      commit('SET_DIALOG_UPLOAD_AUDIO', flag);
    },

    toggleDialogUploadDocument({ commit }, flag) {
      commit('SET_DIALOG_UPLOAD_DOCUMENT', flag);
    },

    toggleDrawerLeft({ state, commit }) {
      commit('SET_DRAWER_LEFT', !state.drawer_left);
    },

    toggleDrawerRight({ commit }, value) {
      commit('SET_DRAWER_RIGHT', value);
    },

    toggleEditDisabled({ commit }, flag) {
      commit('SET_EDIT_DISABLED', flag);
    },

    toggleFileTypeSelected({ commit }, value) {
      commit('SET_FILE_TYPE_SELECTED', value);
    },

    toggleIconsVisible({ commit }, flag) {
      commit('SET_ICONS_VISIBLE', flag);
    },

    toggleJson({ commit }, data) {
      commit('SET_JSON', data);
    },

    toggleJsonDisabled({ commit }, flag) {
      commit('SET_JSON_DISABLED', flag);
    },

    toggleLanguageSelected({ commit }, id) {
      commit('SET_LANGUAGE_SELECTED', id);
    },

    toggleLoading({ commit }, flag) {
      commit('SET_LOADING', flag);
    },

    toggleMenuItem({ dispatch }, id) {
      switch (id) {
        case 1:
          dispatch('toggleDialogUploadAudio', true);
          break;
        case 2:
          dispatch('toggleDialogUploadDocument', true);
          break;
        case 3:
          dispatch('toggleDialogSettings', true);
          break;
        default:
          dispatch(
            'messaging/addMessage',
            {
              text: 'Error: toggleMenuItem',
              display: true,
            },
            {
              root: true,
            },
          );
          break;
      }
    },

    toggleViewer({ state, commit }) {
      if (state.viewer === 'image') {
        commit('SET_VIEWER', 'json');
      } else {
        commit('SET_VIEWER', 'image');
      }
    },
  },

  getters: {
    getJson(state) {
      return state.json;
    },
  },
});
